import { action, makeAutoObservable, runInAction } from 'mobx';

import { TabletsStore } from './tabletsStore';
import {
  ScreenDto, TabletInfoDto, getScreen, saveScreen,
} from '../api/screens';
import { throwError } from '../errorHandler';

export class Screen {
  inProgress = false;

  errors = null;

  tabletsStore: TabletsStore | null = null;

  screenId: string;

  name: string;

  screenDto: ScreenDto | null = null;

  constructor(screenId: string, name: string) {
    this.screenId = screenId;
    this.name = name;
    makeAutoObservable(this, { saveCurrentScreen: action.bound });
  }

  async init() {
    this.inProgress = true;
    try {
      if (!this.screenDto) {
        const screenDto = await getScreen(this.screenId);
        runInAction(() => {
          this.screenDto = screenDto;
          this.tabletsStore = new TabletsStore(this.saveCurrentScreen);
        });
        await this.tabletsStore!.createTabletsFromScreenId(screenDto);
      }
      return this.tabletsStore;
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throwError('Open-screen-error')(e);
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  async saveScreen(name: string, screenInfo: TabletInfoDto[], id?: string) {
    try {
      const data = await saveScreen({ id, name, screenInfo });
      this.screenDto = data;
      return data;
    } catch (e) {
      throwError('ErrorToSave')(e);
      throw e;
    }
  }

  async saveCurrentScreen() {
    if (!this.tabletsStore) {
      return null;
    }
    try {
      const name = this.name || 'Perform';
      const screenInfo:TabletInfoDto[] = this.tabletsStore.tablets
        .filter((tabletLoading) => tabletLoading.tablet)
        .map((tabletLoading): TabletInfoDto => ({
          tabletId: tabletLoading.tablet?.tabletDto.id!,
          boleId: tabletLoading.tablet?.templateData.bole.externalId!,
          boleName: tabletLoading.tablet?.templateData.bole.text!,
          logId: tabletLoading.tablet?.templateData.log?.externalId,
          logName: tabletLoading.tablet?.templateData.log?.text,
          templateName: tabletLoading.tablet?.templateData.templateName!,
          wellboreId: tabletLoading.tablet?.templateData.well.externalId!,
          wellboreName: tabletLoading.tablet?.templateData.well.text,
        }));
      const data = await this.saveScreen(name, screenInfo, this.screenId);
      return data;
    } catch (e) {
      throwError('ErrorToSave')(e);
      throw e;
    }
  }
}
